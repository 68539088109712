import store from '@/store';
import router from '@/router';
import StorageHelper from '@/helpers/localStorageHelper';
import config from '@/config/config';
import AuthAPI from './authApi';

const authTokenKey = 'token-key';
const authTokenTimeKey = 'auth_token_time';
const authRefreshTimeKey = 'refreshing_token_process';
const inlineFilterOrders = 'inline-filter-orders';
const inlineFilterOperations = 'inline-filter-operations';
const inlineFilterCheckout = 'inline-filter-checkout';
const inlineFilterPayout = 'inline-filter-payout';

function getApiDomain() {
  const url = new URL(window.location.href);
  const { hostname } = url;

  const parts = hostname.split('.');

  if (parts.length > 2) {
    parts.shift();
  }

  parts.unshift('api');
  const apiHostname = parts.join('.');
  return `${url.protocol}//${apiHostname}`;
}

class ApiBase {
  static baseUrl() {
    return process.env.VUE_APP_API_URL || getApiDomain();
  }

  static defaultLang() {
    return `${process.env.VUE_APP_LANGUAGE || config.VUE_APP_LANGUAGE}`;
  }

  static authTokenKey() {
    return authTokenKey;
  }

  static authHeaders(token: string | null = null) {
    return {
      Authorization: `Bearer ${token || StorageHelper.get(authTokenKey)}`,
    };
  }

  static setToStore<T, Func extends () => void>(
    self: any,
    name: string,
    data: T,
    callBack?: Func
  ) {
    store.dispatch(name, data);
    if (callBack) {
      callBack();
    }
  }

  static authoriseUser(redirectTo = 'Dashboard') {
    if (redirectTo) {
      router.push({ name: redirectTo });
    }
  }

  static unauthoriseUser(redirectTo = 'SingIn') {
    AuthAPI.logout().finally(() => this.unauthoriseHandle(redirectTo));
  }

  static clearStorage() {
    ApiBase.setToStore(null, 'reset', null);
    StorageHelper.remove(authTokenKey);
    StorageHelper.remove(authTokenTimeKey);
    StorageHelper.remove(authRefreshTimeKey);
    StorageHelper.remove(inlineFilterOrders);
    StorageHelper.remove(inlineFilterOperations);
    StorageHelper.remove(inlineFilterCheckout);
    StorageHelper.remove(inlineFilterPayout);
  }

  private static unauthoriseHandle(redirectTo: string) {
    ApiBase.clearStorage();
    if (redirectTo) {
      router.push({ name: redirectTo });
    }
  }
}

export default ApiBase;
