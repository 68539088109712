<template>
  <svg
    width="21"
    height="13"
    viewBox="0 0 21 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1797 9.5H19.1797M1.17969 9.5H3.17969M3.17969 9.5C3.17969 10.8807 4.29898 12 5.67969 12C7.0604 12 8.17969 10.8807 8.17969 9.5C8.17969 8.11929 7.0604 7 5.67969 7C4.29898 7 3.17969 8.11929 3.17969 9.5ZM18.1797 3.5H19.1797M1.17969 3.5H8.17969M14.6797 6C13.299 6 12.1797 4.88071 12.1797 3.5C12.1797 2.11929 13.299 1 14.6797 1C16.0604 1 17.1797 2.11929 17.1797 3.5C17.1797 4.88071 16.0604 6 14.6797 6Z"
      stroke="#BCBCC3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Settings',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
