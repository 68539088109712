<template>
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.17969 7V11.8002C1.17969 12.9203 1.17969 13.4801 1.39767 13.9079C1.58942 14.2842 1.89516 14.5905 2.27148 14.7822C2.69889 15 3.25868 15 4.3766 15H15.9828C17.1007 15 17.6597 15 18.0871 14.7822C18.4634 14.5905 18.7702 14.2842 18.9619 13.9079C19.1797 13.4805 19.1797 12.9215 19.1797 11.8036V7M1.17969 7V5M1.17969 7H19.1797M1.17969 5V4.2002C1.17969 3.08009 1.17969 2.51962 1.39767 2.0918C1.58942 1.71547 1.89516 1.40973 2.27148 1.21799C2.69931 1 3.25978 1 4.37988 1H15.9799C17.1 1 17.6593 1 18.0871 1.21799C18.4634 1.40973 18.7702 1.71547 18.9619 2.0918C19.1797 2.5192 19.1797 3.07899 19.1797 4.19691V5M1.17969 5H19.1797M5.17969 11H9.17969M19.1797 7V5"
      stroke="#BCBCC3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Balances',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
