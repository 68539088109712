import currenciesIcons from '@/helpers/currenciesIcons';
import EventBus from '@/helpers/event-bus';

export default {
  name: 'List',
  props: [
    'items',
    'type',
    'selectorType',
    'selectItem',
    'selectedItem',
    'bullets',
    'afterMerchantListText',
    'still',
    'multipleFilter',
  ],
  computed: {
    icons: () => currenciesIcons,
  },
  data: () => ({
    selectedMultipleFilters: {},
    returnMultipleFiltersArray: [],
  }),
  beforeMount() {
    const self: any = this as any;
    if (self.selectedItem && self.multipleFilter) {
      for (const sItem of self.selectedItem) {
        const index = self.items.findIndex(
          (item: any) => item.text === sItem.text
        );

        if (index > -1) self.selectedMultipleFilters[index] = sItem;
      }
    }
  },
  mounted() {
    const self: any = this as any;

    EventBus.$on('clear-filters', () => {
      self.selectedMultipleFilters = {};
    });
  },
  beforeDestroy() {
    EventBus.$off('clear-filters');
  },
  methods: {
    handleChange(event: any, item: any, index: number) {
      const self: any = this as any;
      const selected = { ...self.selectedMultipleFilters };

      if (selected[index]) {
        delete selected[index];
      } else {
        selected[index] = item;
      }

      self.selectedMultipleFilters = selected;
      self.returnMultipleFiltersArray = Object.values(selected);
    },
    isMerchant(item: any) {
      return item.parentLabel;
    },
    setParentBulletClass(item: any) {
      const merchantName = item.parentLabel;
      const foundChildActive = (this as any).items.find(
        (i: any) => i.value === (this as any).selectedItem
      );

      if (
        merchantName &&
        foundChildActive &&
        foundChildActive.childLabel === merchantName
      ) {
        return 'active-parrent';
      }
    },
    setChildBulletClass(item: any) {
      const self = this as any;
      if (item.parentLabel && item.parentLabel === self.selectedItem) {
        return 'active-parent-only';
      }

      if (item.value === self.selectedItem) {
        return 'active-child';
      }
    },
  },
};
