<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.56193 4.26806L1.23192 7.89794C1.18305 8.43552 1.1584 8.70657 1.20314 8.96388C1.24312 9.19377 1.32296 9.415 1.43943 9.6172C1.57029 9.84437 1.7637 10.0378 2.14832 10.4224L7.30479 15.5786C8.08894 16.3627 8.48122 16.755 8.93477 16.9023C9.33498 17.0324 9.76678 17.0327 10.167 16.9026C10.622 16.7548 11.0173 16.3599 11.8064 15.5708L15.7517 11.6258C16.5408 10.8367 16.9347 10.4424 17.0826 9.9874C17.2126 9.58721 17.2118 9.15591 17.0818 8.75572C16.9339 8.30076 16.5406 7.90648 15.7515 7.1174L10.6077 1.9739C10.2196 1.58579 10.0255 1.39171 9.79726 1.26026C9.59505 1.14379 9.37381 1.06362 9.14391 1.02365C8.88443 0.978531 8.61098 1.00333 8.06433 1.05303L4.44786 1.38178C3.50657 1.46735 3.03563 1.51032 2.66732 1.71513C2.34259 1.89569 2.07489 2.16338 1.89432 2.48809C1.69052 2.85455 1.64802 3.32213 1.5633 4.254L1.56193 4.26806Z"
      stroke="#BCBCC3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M6.50835 6.3283C6.89744 5.93923 6.89744 5.30842 6.50835 4.91936C6.11926 4.53029 5.48798 4.53029 5.09889 4.91936C4.7098 5.30842 4.70943 5.93908 5.09852 6.32815C5.4876 6.71722 6.11926 6.71737 6.50835 6.3283Z"
      stroke="#BCBCC3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Withdraws',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
