<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1797 9L15.1797 12M15.1797 12L12.1797 15M15.1797 12H1.17969M4.17969 7L1.17969 4M1.17969 4L4.17969 1M1.17969 4H15.1797"
      stroke="#BCBCC3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'ExchangeIcon',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
