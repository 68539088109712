var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.items),function(item,index){return _c('li',{key:index,class:[
      'pointer d-flex',
      _vm.afterMerchantListText && _vm.isMerchant(item) && 'merchant-parent-li',
      _vm.afterMerchantListText && !_vm.isMerchant(item) && 'merchant-child-li',
    ],attrs:{"data-after-merchant-text":_vm.isMerchant(item) && _vm.afterMerchantListText},on:{"click":function($event){if (_vm.multipleFilter) {
        _vm.handleChange($event, item, index);
      }
      if (!_vm.still) {
        item.customHandler
          ? item.customHandler($event)
          : _vm.selectItem(_vm.multipleFilter ? _vm.returnMultipleFiltersArray : item);
      }}}},[(_vm.type === 1)?[_c('div',{staticClass:"w-100 d-flex w-full justify-space-between"},[_c('div',{staticClass:"d-flex"},[(item.symbol)?_c('div',[_c('v-img',{staticClass:"mr-2",attrs:{"width":"19","src":_vm.$async(
                  _vm.getImageUrl(_vm.icons[item.symbol.toUpperCase()]),
                  item.value
                )}})],1):_vm._e(),_c('span',{class:[
              'text-md-body-2 text-caption text-body-2 ',
              _vm.selectorType === 'project-menu'
                ? 'grayBaseText--text'
                : 'grayText--text',
            ]},[_c('span',[_vm._v(_vm._s(item.text))])])]),(item.totalAmount)?_c('span',{staticClass:"computed-rates ml-5"},[_vm._v(" "+_vm._s(_vm.formatCurrencyByCryptoType(item.symbol, item.totalAmount))+" ")]):_vm._e()])]:_vm._e(),(_vm.type === 2)?[_vm._t("listItemContent",function(){return [_c('span',{class:[
            _vm.bullets && 'bullet-points',
            _vm.bullets && _vm.setParentBulletClass(item),
            _vm.bullets && _vm.setChildBulletClass(item),
          ]},[(item.layout)?_c('span',{class:[
              'text-md-body-2 text-caption text-body-2',
              _vm.selectorType === 'project-menu'
                ? 'grayBaseText--text'
                : 'grayText--text',
            ]},[_c('h3',{class:[
                item.layout.description && 'mb-2',
                'blackWhite--text text-body-2 font-weight-bold pl-1 menu-item-text',
              ]},[_vm._v(" "+_vm._s(item.layout.title)+" ")]),(item.layout.description)?_c('p',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.layout.description)+" ")]):_vm._e()]):_c('span',{class:[
              'text-md-body-2 text-caption text-body-2 menu-item-text d-flex align-center',
              _vm.selectorType === 'project-menu'
                ? 'grayBaseText--text'
                : 'grayText--text',
            ]},[(_vm.multipleFilter)?_c('v-checkbox',{staticClass:"mt-0 black--base multiple-filter-checkbox",attrs:{"ripple":false,"hide-details":"","color":item.color || undefined,"value":_vm.selectedMultipleFilters[index]},on:{"click":function($event){if (_vm.multipleFilter) {
                  _vm.handleChange($event, item, index);
                }}},model:{value:(_vm.selectedMultipleFilters[index]),callback:function ($$v) {_vm.$set(_vm.selectedMultipleFilters, index, $$v)},expression:"selectedMultipleFilters[index]"}}):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],1)])]},{"selectorProps":{ item }})]:_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }