<template>
  <svg
    width="19"
    height="13"
    viewBox="0 0 19 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.17969 11H8.17969M17.1797 8L13.1797 12L11.1797 10M1.17969 6H12.1797M1.17969 1H12.1797"
      stroke="#BCBCC3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Operations',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
