<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="a"
      d="M6.50016 2.66667H4.16683C3.23341 2.66667 2.76635 2.66667 2.40983 2.84832C2.09623 3.00811 1.84144 3.26289 1.68166 3.5765C1.5 3.93302 1.5 4.40008 1.5 5.3335V13.3335C1.5 14.2669 1.5 14.7334 1.68166 15.0899C1.84144 15.4035 2.09623 15.6587 2.40983 15.8185C2.766 16 3.2325 16 4.16409 16H12.1692C13.1008 16 13.5667 16 13.9228 15.8185C14.2364 15.6587 14.4921 15.4033 14.6519 15.0897C14.8333 14.7335 14.8333 14.2675 14.8333 13.3359V11M11.5 3.5L6.5 8.5V11H9L14 6M11.5 3.5L14 1L16.5 3.5L14 6M11.5 3.5L14 6"
      stroke="#222122"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Eye',
  };
</script>

<style lang="scss" scoped>
  .a {
    stroke: currentColor;
  }
</style>
