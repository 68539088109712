<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 7.75312V2.25C1.75 2.05109 1.82902 1.86032 1.96967 1.71967C2.11032 1.57902 2.30109 1.5 2.5 1.5H17.5C17.6989 1.5 17.8897 1.57902 18.0303 1.71967C18.171 1.86032 18.25 2.05109 18.25 2.25V7.75312C18.25 15.6281 11.5656 18.2344 10.2344 18.675C10.0831 18.731 9.91686 18.731 9.76562 18.675C8.43438 18.2344 1.75 15.6281 1.75 7.75312Z"
      stroke-width="1.6"
      stroke="#BCBCC3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M14.125 6.75L8.62188 12L5.875 9.375"
      stroke="#BCBCC3"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Settings',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
