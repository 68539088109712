<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4498 15C14.3239 15 13.4112 15.8954 13.4112 17C13.4112 18.1046 14.3239 19 15.4498 19C16.5757 19 17.4884 18.1046 17.4884 17C17.4884 15.8954 16.5757 15 15.4498 15ZM15.4498 15H7.59507C7.12511 15 6.8897 15 6.69622 14.918C6.52553 14.8456 6.37746 14.7291 6.26957 14.5805C6.14864 14.414 6.10001 14.1913 6.00374 13.7505L3.495 2.26465C3.39649 1.81363 3.34657 1.58838 3.22425 1.41992C3.11636 1.27135 2.96831 1.15441 2.79763 1.08205C2.6041 1 2.37001 1 1.89985 1H1.17969M4.23757 4H17.3592C18.0949 4 18.4624 4 18.7093 4.15036C18.9256 4.28206 19.0839 4.48862 19.1529 4.729C19.2318 5.00343 19.1305 5.34996 18.9266 6.04346L17.5153 10.8435C17.3933 11.2581 17.3324 11.465 17.2087 11.6189C17.0995 11.7547 16.9555 11.861 16.7923 11.9263C16.6078 12 16.3887 12 15.9514 12H6.00142M6.27616 19C5.15028 19 4.23757 18.1046 4.23757 17C4.23757 15.8954 5.15028 15 6.27616 15C7.40204 15 8.31474 15.8954 8.31474 17C8.31474 18.1046 7.40204 19 6.27616 19Z"
      stroke="#BCBCC3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Checkouts',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
