<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1797 14.0626V8.63104C17.1797 8.10791 17.1792 7.8462 17.1143 7.60277C17.0567 7.38705 16.9622 7.18291 16.8343 6.99843C16.6899 6.79026 16.4892 6.61763 16.0871 6.27315L11.2871 2.16123C10.5405 1.52165 10.1672 1.20202 9.74707 1.08038C9.37688 0.973205 8.9823 0.973205 8.61211 1.08038C8.1923 1.20193 7.81954 1.52126 7.07405 2.15988L2.27246 6.27315C1.87033 6.61764 1.66973 6.79026 1.52539 6.99843C1.39748 7.18291 1.30224 7.38705 1.24466 7.60277C1.17969 7.8462 1.17969 8.1079 1.17969 8.63104V14.0626C1.17969 14.975 1.17969 15.431 1.33193 15.7908C1.53492 16.2706 1.92401 16.6522 2.41406 16.8509C2.7816 17 3.24755 17 4.17943 17C5.11131 17 5.57777 17 5.94531 16.8509C6.43537 16.6522 6.82436 16.2707 7.02734 15.7909C7.17958 15.431 7.17969 14.9749 7.17969 14.0625V13.0835C7.17969 12.0021 8.07512 11.1255 9.17969 11.1255C10.2843 11.1255 11.1797 12.0021 11.1797 13.0835V14.0625C11.1797 14.9749 11.1797 15.431 11.3319 15.7909C11.5349 16.2707 11.924 16.6522 12.4141 16.8509C12.7816 17 13.2475 17 14.1794 17C15.1113 17 15.5778 17 15.9453 16.8509C16.4354 16.6522 16.8244 16.2706 17.0273 15.7908C17.1796 15.431 17.1797 14.975 17.1797 14.0626Z"
      stroke="#BCBCC3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Dashboard',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
