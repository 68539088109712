<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.17969 12L12.1797 9M12.1797 9L9.17969 6M12.1797 9H1.17969M6.17969 4.24859V4.2002C6.17969 3.08009 6.17969 2.51962 6.39767 2.0918C6.58942 1.71547 6.89516 1.40973 7.27148 1.21799C7.69931 1 8.25978 1 9.37988 1H13.9799C15.1 1 15.6593 1 16.0871 1.21799C16.4634 1.40973 16.7702 1.71547 16.9619 2.0918C17.1797 2.5192 17.1797 3.07899 17.1797 4.19691V13.8036C17.1797 14.9215 17.1797 15.4805 16.9619 15.9079C16.7702 16.2842 16.4634 16.5905 16.0871 16.7822C15.6597 17 15.1007 17 13.9828 17H9.3766C8.25868 17 7.69889 17 7.27148 16.7822C6.89516 16.5905 6.58942 16.2839 6.39767 15.9076C6.17969 15.4798 6.17969 14.9201 6.17969 13.8V13.75"
      stroke="#BCBCC3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Settings',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
