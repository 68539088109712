var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"offset-y":"","disabled":_vm.disabled,"close-on-content-click":!_vm.multipleFilter,"contentClass":`${_vm.contentClass} ${_vm.type} ${
    _vm.type === 'currency'
      ? 'currency-menu'
      : _vm.type === 'bordered-select'
      ? 'bordered-menu'
      : _vm.type === 'second'
      ? 'date-menu'
      : _vm.type === 'project-menu'
      ? 'project-menu'
      : 'filter-menu'
  }`},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('Activator',{class:[_vm.disabled && 'disabled', _vm.fullWidth && 'full-width-activator'],attrs:{"activatorData":{ ..._vm.activatorData, on, attrs },"rounded":_vm.rounded,"pressed":_vm.menu,"upperCase":_vm.upperCase,"textTransformNone":_vm.textTransformNone,"icon":_vm.icon,"multipleFilter":_vm.multipleFilter},scopedSlots:_vm._u([{key:"buttonContent",fn:function(){return [_vm._t("activator",null,{"activatorData":{ ..._vm.activatorData, on, attrs }})]},proxy:true}],null,true)})]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.icon)?_c('ul',{staticClass:"icon-list custom-select-ul",class:[_vm.scrollable && 'scrollable'],style:(_vm.contentHeight && 'height: ' + _vm.contentHeight + 'px')},[_c('List',{attrs:{"type":1,"selectItem":_vm.selectItem,"selectedItem":_vm.selectedItem,"bullets":_vm.bullets,"selectorType":_vm.type,"items":_vm.filteredOptions || _vm.items,"afterMerchantListText":_vm.afterMerchantListText,"multipleFilter":_vm.multipleFilter,"still":_vm.still}})],1):_c('ul',{staticClass:"custom-select-ul",class:[_vm.scrollable && 'scrollable'],style:(_vm.contentHeight && 'height: ' + _vm.contentHeight + 'px')},[_c('List',{staticClass:"icon-list",attrs:{"type":2,"selectItem":_vm.selectItem,"selectedItem":_vm.selectedItem,"items":_vm.filteredOptions || _vm.items,"bullets":_vm.bullets,"selectorType":_vm.type,"afterMerchantListText":_vm.afterMerchantListText,"still":_vm.still,"multipleFilter":_vm.multipleFilter}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }