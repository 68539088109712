<template>
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.17969 1L5.17969 5L1.17969 9"
      stroke="#222122"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'ArrowRight',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
