<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.17969 7V17.4C1.17969 17.9601 1.17969 18.2399 1.28868 18.4538C1.38455 18.642 1.53742 18.7952 1.72559 18.8911C1.93929 19 2.21918 19 2.77814 19H13.1798M15.1797 6L11.1797 10L9.17969 8M5.17969 11.8002V4.2002C5.17969 3.08009 5.17969 2.51962 5.39767 2.0918C5.58942 1.71547 5.89516 1.40973 6.27148 1.21799C6.69931 1 7.25978 1 8.37988 1H15.9799C17.1 1 17.6597 1 18.0876 1.21799C18.4639 1.40973 18.7702 1.71547 18.9619 2.0918C19.1799 2.51962 19.1799 3.07969 19.1799 4.19978L19.1799 11.7998C19.1799 12.9199 19.1799 13.48 18.9619 13.9078C18.7702 14.2841 18.4639 14.5905 18.0876 14.7822C17.6602 15 17.1012 15 15.9833 15H8.3766C7.25868 15 6.69889 15 6.27148 14.7822C5.89516 14.5905 5.58942 14.2842 5.39767 13.9079C5.17969 13.4801 5.17969 12.9203 5.17969 11.8002Z"
      stroke="#BCBCC3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Orders',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
